<!-- 智慧服务/财务管理/薪酬管理-薪酬项目-类型管理 -->
<template>
    <div class="info">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
				<global-page-back
                	:detailPageTitle="assetMaintenance.name+' - 薪酬项目详情'"
                	@handlerGlobalType="goBack"
            	>
            	</global-page-back>

                <div class="content-main">
                    <div class="filter-wrap">
                        <div style="display: flex;width: 100%;">
                            <expand-filter
                                :formData="formData"
                                marginBottom="0px"
                                :closeWidth="85"
                                @clickBtn="clickBtn"
                                @changeBtnFormType="changeBtnFormType"
                            ></expand-filter>
                            <div class="button-line" v-has-permi="['salaryProjectInfo:revoke','salaryProjectInfo:send','salaryProjectInfo:export']"></div>
                            <el-button
                                v-if="!issue"
                                v-has-permi="['salaryProjectInfo:revoke']"
                                type="warning"
                                @click="handleWithdraw"
                            >撤回发放</el-button>
                            <el-button
                                v-else
                                v-has-permi="['salaryProjectInfo:send']"
                                type="primary"
                                class="filter-right-button"
                                @click="handleProvideMoney"
                            >薪酬发放</el-button>
                            <el-button
                                :loading="exportLoading"
                                v-has-permi="['salaryProjectInfo:export']"
                                type="enquiry"
                                @click="handleExport"
                            >导出</el-button>
                            <span class="tip">单位：元</span>
                        </div>
                    </div>
                    <table-data :config="table_config" :tableData="table_data" v-loading="loadingTable"
                        backgroundHeader="#FAFBFC" :headerStyle="{ height: '50px' }" :rowStyle="{ height: '50px' }" ref="table"
                        id="table">
                        <template v-slot:setting="slotData">
                            <div class="setIcon" @click="showDrawer()">
                                <img :src="setIcon" alt="" />
                            </div>
                        </template>
                        <template v-slot:operation="slotData" v-if="issue">
                            <el-button @click="handleEdit(slotData.data)" v-if="showEdit"
                                v-hasPermi="['salaryProjectInfo:edit']" style="
                                    color: #177ee6;
                                    border: none;
                                    background: none;
                                ">
                                编辑
                            </el-button>
                            <el-button @click="handleDelete(slotData.data)" v-hasPermi="['salaryProjectInfo:delete']" style="
                                    color: #e63a2e;
                                    border: none;
                                    background: none;
                                ">
                                删除
                            </el-button>
                        </template>
                    </table-data>
                    <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                        @pagination="getList(true)" />
                    <!-- 薪酬发放 -->
                    <dialog-wrapper :dialogObj="dialogObj" @handleClose="handleClose">
                        <div>
                            <div class="dialogTip">{{ issueContent }}</div>
                            <div style="text-align: right">
                                <el-button @click="handleClose" class="dialog-reset-button">取消</el-button>
                                <el-button @click="submitiSsue()" v-if="issue" type="primary">薪酬发放</el-button>
                                <el-button @click="submitiSsue()" v-else type="primary">撤回发放</el-button>
                            </div>
                        </div>
                    </dialog-wrapper>
                    <!-- 编辑 -->
                    <dialog-wrapper :dialogObj="dialogEditObj" @handleClose="handleEditClose" class="box">
                        <el-form :model="ruleForm" ref="ruleForm" label-position="right" label-width="94px" class="common-form">
                            <div v-for="(item, index) in ruleForm.typeList" :key="index">
                                <el-form-item :label="item.typeName" :prop="'typeList.' + index + '.typeValue'" :rules="[
                                    {
                                        required: true,
                                        message: `${item.typeName}不能为空,只支持数字和小数点`,
                                        trigger: 'blur',
                                    },
                                    {
                                        validator: validateType,
                                        trigger: 'blur',
                                    },
                                ]">
                                    <el-input v-model="item.typeValue" show-word-limit @input="
                                        restrictWeightInput($event, item)
                                        " maxlength="20" :placeholder="`请填写${item.typeName}`" class="input-width-small"></el-input>
                                    <!-- <el-input-number style="text-align: left;"  v-model="item.typeValue" :precision="2" type="number" :controls="false"></el-input-number> -->
                                </el-form-item>
                            </div>
                            <div style="text-align: right">
                                <el-button @click="handleEditClose" class="dialog-reset-button">取消</el-button>
                                <el-button @click="submitForm()" type="primary">确定</el-button>
                            </div>
                        </el-form>
                    </dialog-wrapper>
                    <!-- 设置 -->
                    <div class="drawerExam-container">
                        <table-item-drawer :isShowDrawer.sync="isShowDrawer" @closeDrawer="isShowDrawer = $event"
                            :menuList="menuList" />
                    </div>
                </div>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import { FilterData, Loading, Pagination, DialogWrapper } from "common-local";
import TableData from "../Sub/TableData";
import ExpandFilter from "../Sub/ExpandFilter.vue";
import selectTree from "../Sub/selectTree";
import TableItemDrawer from "./TableItemDrawer.vue";
import { downloadFile } from "@/libs/utils.js";
import { SalaryProjectModel } from "@/models/SalaryProject.js";
import GlobalPageBack from "../Sub/GlobalPageBack/index.vue";
// 逻辑运算
import system from "@/operations/system.js";
export default {
    name: "SalaryProjectDetail",
    components: {
        selectTree,
        FilterData,
        Loading,
        TableData,
        Pagination,
        ExpandFilter,
        DialogWrapper,
        TableItemDrawer,
		GlobalPageBack
    },
    props: {
        routerReturn: {
            type: String,
            default: "",
        },
        assetMaintenance: {
            type: Object,
        },
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            exportLoading: false,
            total: 0,
            issue: true, //薪酬发放//true:发放，false:撤回
            listQuery: {
                pageNum: 1,
                pageRow: 20,
                schoolId: "",
                teacherName: "",
                projectId:this.assetMaintenance.id,
				departmentName:'',
				teachingPost:''
            },
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "请输入教师名称",
                        key: "teacherName",
                    },
					{
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '部门名称',
                        key: 'departmentName',
                        list: []
                    },
					{
                        type: 'select',
                        label: '',
                        value: '',
                        placeholder: '职务名称',
                        key: 'teachingPost',
                        list: []
                    }],
                btnList: [{
                    type: "primary",
                    text: "查询",
                    fn: "primary",
                    auth: ["salaryProjectInfo:list"],
                }]
            },
            table_config: {
                thead: [
                    {
                        label: "教师",
                        prop: "teacherName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "手机号",
                        prop: "phone",
                        align: 'center'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        tooltip: true,
                        slotName: "operation",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        type: "setHeader",
                        slotName: "setting",
                        fixed: "right",
                        labelWidth: "80",
                    },
                ],
                check: false,
                height: "",
                leftEmpty:true
            },
            table_data: [],
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "640px",
            },
            dialogEditObj: {
                title: "",
                dialogVisible: false,
                width: "640px",
            },
            issueContent: "", //薪酬发放弹框内容
            // 编辑
            ruleForm: {
                teacherId: "",
                projectId: "",
                typeList: [],
            },
            // rules:{
            //     typeValue: [
            //         { validator:this.validateTypeValue, trigger: 'blur' },
            //     ],
            // },
            isShowDrawer: false,
            menuList: [], //动态列表数据
            setMenu: [],
            issueData: {
                sendStatus: "", //发放状态1已发送0未发放
                projectId: this.assetMaintenance.id,
            }, //薪酬发放数据
            exportList: {
                schoolId: this.$store.state.schoolId,
                teacherName: "",
                projectId: this.assetMaintenance.id,
                typeIds: [],
            },
            showEdit: true, //编辑按钮
        };
    },
    created() {
        if (this.assetMaintenance.sendStatus === 0) {
            this.issue = true;
        } else {
            this.issue = false;
        }
        this.getList();
        //初始化表头
        if (this.menuList.length > 0) {
            this.$nextTick(() => {
                this.setMenu = this.menuList.filter((i) => i.state);
                this.setMenu.forEach((item) => {
                    this.table_config.thead.push({
                        label: item.label,
                        prop: item.label,
                    });
                });
                this.$refs.table.$refs.multipleTable.doLayout();
            });
        }
		this.getDepartmentList();
		this.getTeachingPostList();
    },
    mounted() {
        this.$nextTick(() => {
            this.table_config.height =
                document.body.clientHeight - this.$refs.table.$el.offsetTop - 135;
        })
    },
    computed: {
        setIcon() {
            return require("@/assets/images/setIcon.png");
        },
    },
    methods: {
		getDepartmentList(){
			this._fet("/school/schoolOrgan/listByCondition", {
        		schoolId: this.$store.state.schoolId,
        		organType: '1',
        	}).then((res) => {
        		if (res.data.code === "200") {
                    this.formData.data.forEach((item) => {
                        if (item.key === "departmentName") {
                            item.list = res.data.data.list.map((i) => ({
                                label: i.name,
                                value: i.id,
                            }));
                        }
                    });
        		} else if (res.data && res.data.msg) {
        			this.$message.error(res.data.msg)
        		}
        	})
		},
		getTeachingPostList(){
			this._fet("/school/schoolDictValue/listByCondition", {
        		schoolId: this.$store.state.schoolId,
        		dictKey: "teachingPost",
        	}).then((res) => {
        		if (res.data.code == '200') {
                    this.formData.data.forEach((item) => {
                        if (item.key === "teachingPost") {
                            item.list = res.data.data.map((i) => ({
                                label: i.name,
                                value: i.id,
                            }));
							console.log('this.formData.data===',this.formData.data);
                        }
                    });
        		} else{
        			this.$message.error(res.data.msg)
        		}
        	})
		},
        /**
         * @Description: 校验薪资-只能输入数字，最多保留两位小数
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-16 14:28:05
         */
        restrictWeightInput(event, item) {
            // console.log(event.detail.value, Number(event.detail.value), '数量')
            console.log(event, "长度");
            let val = event;
            val = val.replace(/[^\d.]/g, ""); // 清除"数字"和"."以外的字符
            val = val.replace(/\.{2,}/g, "."); // 不能连续输入两个及以上小数点
            val = val
                .replace(".", "$#$")
                .replace(/\./g, "")
                .replace("$#$", "."); // 只保留第一个".", 清除多余的"."
            val = val.replace(/^\./g, ""); //验证第一个字符是数字而不是.
            val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入一个小数
            if (val.indexOf(".") < 0 && val != "") {
                //如果没有小数点，首位不能为类似于01、02的数字
                val = parseFloat(val);
            }
            setTimeout(() => {
                item.typeValue = val.toString();
                this.$forceUpdate();
            }, 0);
        },

        validateType(rule, value, callback) {
            if (value === "") {
                callback(new Error("请填写薪资"));
            } else {
                callback();
            }
        },
        /**
         * @Description: 返回
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:20:47
         */
        goBack() {
            this.$emit("backList");
        },
        /**
         * @Description: 获取列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:23:24
         */
        getList(isPaging) {
            this.loadingTable = true;
            if (!isPaging) {
                this.listQuery.pageNum = 1;
            }
            this.listQuery.schoolId = this.$store.state.schoolId;
            const salaryProjectModel = new SalaryProjectModel();
            salaryProjectModel
                .getSalaryProjectDetailList(this.listQuery)
                .then((res) => {
                    if (res.data.code === "200") {
                        let arr = [];
                        //处理返回的数据
                        res.data.data.list.forEach((j) => {
                            j.typeList.forEach((i) => {
                                j[i.typeName] = i.typeValue;
                                arr.push({
                                    label: i.typeName,
                                    id: i.typeId,
                                    prop: i.typeValue,
                                    state: true,
                                });
                            });
                        });
                        //处理数据-label去重
                        this.menuList = this.unique(arr, "label");
                        this.table_data = res.data.data.list;
                        this.total = res.data.data.totalCount;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;
                });
        },
        /**
         * @Description: 去重
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-04 15:48:03
         */
        unique(arr, val) {
            const res = new Map();
            return arr.filter(
                (item) => !res.has(item[val]) && res.set(item[val], 1),
            );
        },
        /**
         * @Description: 展示、收起
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-05-04 16:10:47
         */
        changeBtnFormType() {
            this.formData.btnFormType = !this.formData.btnFormType;
            this.$nextTick(() => {
                this.table_config.height =
                    document.body.clientHeight -
                    document.getElementById("table").offsetTop -
                    135;
            });
        },
        /**
         * @Description: 查询、新增类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:19:28
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    this.getList(1);
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 设置
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-25 16:58:27
         */
        showDrawer() {
            this.isShowDrawer = true;
            // this.$emit("closeDrawer", val);
        },
        /**
         * @Description: 撤回发放
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 17:05:44
         */
        handleWithdraw() {
            this.dialogObj.title = "撤回发放";
            this.issueContent =
                "撤回发放后教师将查询不到当前薪酬项目，是否继续执行发放操作？";
            this.dialogObj.dialogVisible = true;
            this.issueData.sendStatus = 0;
        },
        /**
         * @Description: 薪酬发放
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 17:06:01
         */
        handleProvideMoney() {
            this.dialogObj.title = "薪酬发放";
            this.issueContent =
                "薪酬发放后教师将会查询到当前薪酬项目，是否继续执行发放操作？";
            this.dialogObj.dialogVisible = true;
            this.issueData.sendStatus = 1;
        },
        /**
         * @Description: 薪酬发放、撤回发放-关闭、取消
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:25:34
         */
        handleClose() {
            this.dialogObj.dialogVisible = false;
        },
        /**
         * @Description: 薪酬发放、撤回发放
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:26:08
         */
        submitiSsue() {
            const salaryProjectModel = new SalaryProjectModel();
            salaryProjectModel
                .getSalaryProjectDetailIssue(this.issueData)
                .then((res) => {
                    if (res.data.code === "200") {
                        if (this.issueData.sendStatus === 1) {
                            this.$message.success("薪酬发放成功");
                        } else {
                            this.$message.success("已撤回发放");
                        }
                        this.issue = !this.issue;
                        this.handleClose();
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 导出
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 17:06:18
         */
        handleExport() {
            console.log(
                this.ruleForm.typeList,
                "this.11111111111ruleForm.11111111111111typeList",
                this.menuList,
            );
            let typeIdList = [];
            this.menuList.map((item) => {
                if (item.state) {
                    typeIdList.push(item.id);
                }
            });
            this.exportList.teacherName = this.listQuery.teacherName;
            this.exportList.typeIds = typeIdList;
            downloadFile(
                {
                    url: "/school/salaryTeacher/exportInfo",
                    form: this.exportList,
                },
                () => { },
                () => { },
            );
        },
        /**
         * @Description: 操作-编辑-提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-24 10:49:04
         */
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    const salaryProjectModel = new SalaryProjectModel();
                    salaryProjectModel
                        .getSalaryProjectDetailEdit(this.ruleForm)
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("保存成功");
                                this.handleEditClose();
                                this.getList();
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        });
                }
            });
        },
        /**
         * @Description: 操作-编辑
         * @DoWhat: 取消编辑
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-24 10:48:08
         */
        handleEditClose() {
            this.$refs.ruleForm.resetFields();
            this.dialogEditObj.dialogVisible = false;
        },
        /**
         * @Description: 操作-编辑
         * @DoWhat:打开编辑弹窗
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:26:42
         */
        handleEdit(data) {
            console.log(data, "9999999999999");
            data.typeList.forEach((item) => {
                this.ruleForm.typeList.map((item1) => {
                    if (item1.typeId === item.typeId) {
                        item1.typeValue = item.typeValue;
                    }
                });
            });
            if (this.ruleForm.typeList.length === 0) {
                this.showEdit = false;
            } else {
                this.showEdit = true;
            }
            // console.log(this.ruleForm.typeList,'this.ruleForm.typeList');
            this.ruleForm.teacherId = data.teacherId;
            this.ruleForm.projectId = data.projectId;
            this.dialogEditObj.dialogVisible = true;
            this.dialogEditObj.title = `编辑薪资 (${data.teacherName})`;
        },
        /**
         * @Description: 操作-删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-04-23 15:27:11
         */
        handleDelete(data) {
            this.$confirm("是否确定删除, 删除后不可恢复?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const salaryProjectModel = new SalaryProjectModel();
                    salaryProjectModel
                        .getSalaryProjectDetailDelete({
                            teacherId: data.teacherId,
                            projectId: data.projectId,
                        })
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("删除成功");
                                this.getList();
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        });
                })
                .catch(() => { });
        },
    },
    watch: {
        // 监听menuList的变化
        menuList: {
            handler: function (newnews) {
                this.table_config.thead =
                    this.$options.data.call(this).table_config.thead;
                this.ruleForm.typeList =
                    this.$options.data.call(this).ruleForm.typeList;
                this.menuList = newnews;
                this.setMenu = this.menuList.filter((i) => i.state);
                this.setMenu.forEach((item) => {
                    this.table_config.thead.push({
                        label: item.label,
                        prop: item.label,
                        align: "right",
                    });
                    this.ruleForm.typeList.push({
                        typeName: item.label,
                        typeValue: item.prop,
                        typeId: item.id,
                    });
                });
                if (this.ruleForm.typeList.length === 0) {
                    this.showEdit = false;
                } else {
                    this.showEdit = true;
                }
                // this.getList();
                this.$nextTick(() => {
                    this.$refs.table.$refs.multipleTable.doLayout();
                });
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 20px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .filter-right-button {
        border-color: transparent;
    }

    .expand-filter {
        padding: 0;

        .date-picker-wrap {
            display: flex;
            align-items: center;

            .date-icon {
                margin-left: 10px;
                user-select: none;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 14px;
                height: 14px;
                background: #d4d6d9;
                border-radius: 50%;
                color: #ffffff;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    .operation-btn-view {
        flex-shrink: 0;
        padding-right: 0;

        ::v-deep .filter-right-button:hover {
            filter: brightness(1.1);
        }
    }

    .tip {
        line-height: 32px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6d7073;
        margin-left: auto;
    }
}

.body-title {
    display: flex;
    background-color: #ffffff;
    align-items: center;
    padding: 10px 10px 10px 0;
    // margin-top: 8px;
    border-bottom: 1px solid #edf0f2;
    box-sizing: border-box;
    border-radius: 4px;

    .title {
        font-weight: bold;
        font-size: 15px;
        font-family: Microsoft YaHei;
		cursor: pointer;
    }
}

.dialogTip {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 30px;
    color: #2b2f33;
    margin-bottom: 10px;
}

.setIcon {
    width: 28px;
    height: 28px;
    line-height: 30px;

    &:hover {
        background: #ebedf0;
        border-radius: 4px;
    }
}

.drawerExam-container {
    ::v-deep .v-modal {
        position: absolute;
    }
}

.box {
    /deep/.el-dialog {
        // height: 600px;
        max-height: 600px;
    }

    /deep/ .el-input-number .el-input__inner {
        text-align: left;
        width: 230px;
    }
}
</style>
